<template>
  <div>
    <tgl-card-header>
      <h1>Notificaciones</h1>
    </tgl-card-header>
    <tgl-card-content>
      <datatables-wrapper
        @optionsChanged="getItems"
        @updateTable="getItems"
        :loading="loading"
        :data="data"
        :headers="headers"
        filterType="simple"
        :parameters="['message']"
      />
    </tgl-card-content>
  </div>
</template>

<script>
import TglCardContent from "@/components/Cards/TglCardContent.vue";
import TglCardHeader from "@/components/Cards/TglCardHeader.vue";
import DatatablesWrapper from "@/components/Notifications/DatatablesWrapper.vue";
export default {
  components: {
    DatatablesWrapper,
    TglCardContent,
    TglCardHeader,
  },
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "message",
      },
      { text: "Estado", value: "seen" },
      { text: "Timestamp", value: "timestamp" },
      { text: "", value: "data-table-expand" },
      //value = nombre del atributo dentro del array de items
    ],
    data: {},
    loading: false,
  }),

  methods: {
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("notification/getNotificationsV1", options)
        .then((data) => (this.data = data))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>

<style>
</style>