<template>
  <notification-table></notification-table>
</template>

<script>
import NotificationTable from "../components/Notifications/NotificationsTable.vue";
export default {
  components: {
    NotificationTable,
  },
};
</script>
